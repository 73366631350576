import React, { ReactElement, useEffect, useState } from "react";
import { MonsterForm } from "../../components/create_homebrew/monster/MonsterForm";
import { Box, useBoolean } from "@chakra-ui/react";
import { Break } from "../../components/styled/Break";
import { MyNavbar } from "../../components/common/navbar/MyNavbar";
import { Title } from "../../components/styled/Text";
import { useApiClient } from "../../context/ApiClient";
import { uploadMonsterPdf } from "../../components/create_homebrew/pdfgeneration/MonsterPdf";
import { MonsterEntity } from "../../constants/Monster";
import { useAuthState } from "../../context/AuthState";
import { generateS3Key, parseUrlParams } from "../../Utils";
import { getEntity, updateEntity } from "../../api/common";
import { COLLECTIONS } from "../../constants/General";
import { navigate } from "gatsby";
import { useAlertContext } from "../../context/Alert";

export default function Monster(): ReactElement {
  const [title, setTitle] = useState("Monster Maker");
  const [referenceId, setReferenceId] = useState("");
  const [monsterEntity, setMonsterEntity] = useState(undefined);
  const [isLoading, setIsLoading] = useBoolean();
  const authState = useAuthState();
  const apiClient = useApiClient().getClient();
  const s3Directory = COLLECTIONS.Monsters;

  const { alert, addAlert } = useAlertContext();

  useEffect(() => {
    const paramMap = parseUrlParams(location.search);
    setReferenceId(paramMap.get("referenceId"));
    getEntity(apiClient, COLLECTIONS.Monsters, paramMap.get("referenceId")).then((response) => {
      setMonsterEntity(response.data);
      setTitle(response.data.name);
    });
  }, []);

  const onSubmit = async (values: MonsterEntity) => {
    if (!authState.loggedIn) {
      addAlert(false, "User is logged out. Cannot edit a form like this :(");
    } else {
      setIsLoading.on();
      try {
        await uploadMonsterPdf(values, authState, values.fileS3Key);
        await updateEntity(apiClient, referenceId, COLLECTIONS.Monsters, values);
      } catch (error) {
        setIsLoading.off();
        addAlert(false, `Something went wrong with monster generation. ${error}`);
      } finally {
        navigate("/saved/monster");
      }
    }
  };

  return (
    <Box backgroundColor="monster.background" minH="100vh">
      <MyNavbar />
      {alert}
      <Break height="5vh" />
      <Box bg="white" m="0 5vw 0 5vw" p="0 3vw 0 3vw">
        <Title text={title} fontSize="2.3rem" />
        {monsterEntity === undefined ? <div /> : <MonsterForm setTitle={setTitle} onSubmit={onSubmit} monsterEntity={monsterEntity} />}
      </Box>
      <Break height="5vh" />
    </Box>
  );
}
